import React from 'react'
// Partner images
import sunlifeLogo from '../../images/trusted-by/color/sunlife-logo-color.png'
import yamahaLogo from '../../images/trusted-by/color/yamaha-logo-color-r.png'
import mapfreLogo from '../../images/trusted-by/color/mapfre-logo-color-r.png'
import bswiftLogo from '../../images/trusted-by/color/bswift-logo-color.png'
import formaLogo from '../../images/trusted-by/color/forma-logo-color.png'
import benepassLogo from '../../images/trusted-by/color/benepass-logo-color.png'
import modernaLogo from '../../images/trusted-by/color/moderna-logo-color.png'
import himsHersLogo from '../../images/trusted-by/color/hims-hers-logo-color.png'
import rightwayLogo from '../../images/trusted-by/color/rightway-health-logo-color.png'
import blueBirdBioLogo from '../../images/trusted-by/color/bluebirdbio-logo-color.png'

const companyImages = [
  {
    url: sunlifeLogo,
    alt: 'Sun Life logo.'
  },
  {
    url: yamahaLogo,
    alt: 'Yamaha logo.'
  },
  {
    url: mapfreLogo,
    alt: 'Mapfre logo.'
  },
  {
    url: formaLogo,
    alt: 'Forma logo.'
  },
  {
    url: modernaLogo,
    alt: 'Moderna logo.'
  },
  {
    url: benepassLogo,
    alt: 'Benepass logo.'
  },
  {
    url: bswiftLogo,
    alt: 'bswift logo.'
  },
  {
    url: himsHersLogo,
    alt: 'Hims & Hers logo.'
  },
  {
    url: rightwayLogo,
    alt: 'Rightway Health logo.'
  },
  {
    url: blueBirdBioLogo,
    alt: 'bluebirdbio logo.'
  }
]

const partnerContainer =
  'grid grid-cols-3 gap-6 mt-6 md:flex md:flex-wrap md:pl-12 md:pb-8 md:pt-16 md:mt-0 md:rounded-bl-xxlg'

const partnerContainerBorder = `${partnerContainer} md:border-l-2 md:border-b-2 border-gp-violet`

export default function PartnerImages({ border }) {
  return (
    <>
      <div className={`${border ? partnerContainerBorder : partnerContainer}`}>
        {companyImages.map((e, i) => (
          <img
            key={i}
            src={e.url}
            alt={e.alt}
            className="w-full md:h-10 md:mr-12 md:w-auto md:max-w-none"
          />
        ))}
      </div>
    </>
  )
}
