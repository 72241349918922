import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  metaTitle,
  image = false,
  type,
  canonicalLink,
  location,
  doNotCanonicalize = false,
  noIndex = false
}) {
  const { site, layout } = useStaticQuery(detailsQuery)
  const {
    siteMetadata: { siteUrl: staticBaseUrl }
  } = site
  const metaDescription = description || site.siteMetadata.description
  const ogType = type || 'website'
  const staticHref =
    staticBaseUrl && location?.pathname
      ? `${staticBaseUrl}${location?.pathname}`
      : null
  const locationBasedHref =
    location?.origin && canonicalLink?.url
      ? `${location.origin}${canonicalLink.url}`
      : location?.href
  const canonicalHref = doNotCanonicalize
    ? null
    : (locationBasedHref ? locationBasedHref : staticHref)?.replace(/\/+$/, '')
  const canonicalKey = canonicalLink && canonicalLink.title

  if (!location) {
    console.log(
      'WARNING: No location set. Make sure to pass the location to the SEO component.' +
        `Details: title=${title}, canonicalHref=${canonicalHref}, canonicalKey=${canonicalKey}, ` +
        `canonicalLink=${canonicalLink}, location=${JSON.stringify(location)}`
    )
  }

  const { images } = layout || {}
  const _image =
    image || (images.length > 0 ? 'https:'.concat(images[0].file.url) : false)

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={(metaTitle && metaTitle) || title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        canonicalHref
          ? [
              canonicalKey
                ? {
                    rel: 'canonical',
                    key: canonicalKey,
                    href: canonicalHref
                  }
                : {
                    rel: 'canonical',
                    href: canonicalHref
                  }
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: `${title} | ${site.siteMetadata.title}`
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          ...(_image && {
            property: 'og:image',
            content: _image
          })
        },
        {
          property: `og:type`,
          content: ogType
        },
        {
          name: `twitter:card`,
          content: _image ? 'summary_large_image' : 'summary'
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: `${title} | ${site.siteMetadata.title}`
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          ...(_image && {
            property: 'twitter:image',
            content: _image
          })
        },
        {
          ...(noIndex && {
            name: 'robots',
            content: 'noindex'
          })
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  metaTitle: PropTypes.string
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    layout: contentfulEvergreenContent(readableId: { eq: "layout-og-image" }) {
      id
      images {
        file {
          url
        }
      }
    }
  }
`
